<template>
  <LoadBlock :loader="loader">
    <div class="whiteboard-wrapper" ref="whiteboardWrapper"></div>
  </LoadBlock>
</template>

<script>

import {getFileClassSrc} from '@store/utils';
const whiteboardWebComponentInit = () => import('@plugin/whiteboard-web-component');

import timerRepeat from '@service/timerRepeat';
import LoadBlock from '@loading/component/LoadBlock';

export default {
  name: 'BoardItemPage',
  components: {LoadBlock},
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loader: false,
      
      board: null,
      boardElements: [],
      instance: null,
      
      timerRun: null,
    };
  },
  methods: {
    onItemsChange(items) {
      RequestManager.Board.overwriteElementListById({
        id: this.id,
        elementList: items.map( item => ({ ...item, props: JSON.stringify(item.props)}) ),
      });
    },
    initBoardPlugin(boardElements) {
      this.instance = new window.WhiteBoardWebComponent({
        storage: {
          /**
           * @param {File} file
           * @returns {Promise<{src: String}>}
           */
          upload: async (file) => {
            this.loader = true;
            try {
              const src = await getFileClassSrc(new FileClass({ file: file }));
              this.loader = false;
              return {src: src};
            } catch (e) {
              this.loader = false;
              throw e;
            }
          },
        },
        items: boardElements,
        onItemsChange: this.onItemsChange,
      });
      this.$refs.whiteboardWrapper.appendChild(this.instance);
    }
    
    //
    
  },
  async mounted() {
    await whiteboardWebComponentInit();
    this.board = await RequestManager.Board.getById({id: this.id});
    
    const boardElements = [];
    for(let i = 0; i < this.board.elements.length; i++) {
      try {
        let props = JSON.parse(this.board.elements[i].props);
        boardElements.push({
          ...this.board.elements[i],
          props: props,
        });
      } catch (e) {
      
      }
    }
    
    this.$nextTick( () => {
      this.initBoardPlugin(boardElements)
    });
  
  
    this.timerRun = timerRepeat();
    this.timerRun.setFunction( () => {
      return new Promise( (resolveTimeoutFunc) => {
        RequestManager.Board.getById({id: this.id}).then(
            (board) => {
              const boardElements = [];
              for(let i = 0; i < board.elements.length; i++) {
                try {
                  let props = JSON.parse(board.elements[i].props);
                  boardElements.push({
                    ...board.elements[i],
                    props: props,
                  });
                } catch (e) {}
              }
  
              this.instance.items = boardElements;
              resolveTimeoutFunc({});
            }
        ),
        (error) => {
          resolveTimeoutFunc({})
        }
      });
    });
  
    // reload disable
    // setTimeout(() => {
    //   this.timerRun.run();
    // }, 5000);
  },
  unmounted() {
    this.instance.onItemsChange = null;
  
    // reload disable
    // this.timerRun.stop();
    // setTimeout(() => {
    //   this.timerRun.stop();
    // }, 6000);
  },
};
</script>

<style lang="scss" scoped>
.whiteboard-wrapper {
  position: relative;
  display: flex;
  flex: 1;
}
::v-deep(whiteboard-web-component) {
  flex: 1;
}
</style>