<template>
  <div class="load-block" :class="{ 'load-block--active': loader}">
    <template v-if="loader">
      <div class="loading-block">
        <!-- https://codepen.io/camdenfoucht/pen/BVxawq -->
        <div class="nb-spinner"></div>
      </div>
    </template>
  
    <slot/>
  </div>
</template>

<script>

export default {
  name: 'LoadBlock',
  props:{
    loader: {
      type: Boolean,
    },
  },
};
</script>

<style scoped lang="scss">
.load-block {
  &.load-block--active{
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
  }
  
  .loading-block {
    position: absolute;
    z-index: 9998; // Должен быть меньше чем у основной страницы
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  
    /*background: #5CD182;*/
    //background: #e5e5e5;
    background: rgb(229 229 229 / 40%);
    
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .nb-spinner {
    width: 128px;
    height: 128px;
    margin: 0;
    background: transparent;
    border-top: 4px solid #f5ac1c;
    border-right: 4px solid transparent;
    border-radius: 50%;
    -webkit-animation: 1s spin linear infinite;
    animation: 1s spin linear infinite;
  }
  
  @keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}

</style>
